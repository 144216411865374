import React from "react";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import Banda04 from "../images/banda-04-04.png";
import Banda11 from "../images/banda-04-11.png";
import Banda18 from "../images/banda-04-18.png";
import Banda14 from "../images/banda-04-14.png";

import { Link } from "gatsby";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "News",
    link: "news",
  },
];

class News extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentImage: LosMuetros2,
  //   };
  // }

  // handleImageSwitch = () => {
  //   this.setState((prevState) => ({
  //     currentImage:
  //       prevState.currentImage === LosMuetros2 ? LosMuetros : LosMuetros2,
  //   }));
  // };

  render() {
    return (
      <Layout
        title="News | RF Dance"
        pathname="news/"
        noHero="no-hero"
        className="news_page"
      >
        <Hero titleRed={`RF`} titleWhite={`News`} />

        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              {/* English */}

              <div className="english-event">


                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://rfdance.com/teams/">
                      <img src={Banda14} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Team Placements 2025</h3>
                    <div className="news_content_area">
                      <p>
                        Ready to find your place in the dance community? RF Team Placements 2025 are happening this April! Join us at RF Dance Center for free placements in Salsa, Cumbia, and Bachata.  All levels are welcome—no experience necessary! Come discover your passion, challenge yourself, and join the family. Register now at rfdance.com/teams, as space is limited! Wear comfortable rehearsal attire and dance shoes. Questions? Contact us at info@rfdance.com or 657.293.5161.
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://rfdance.com/teams/">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/04/25">
                      <img src={Banda04} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 04/04</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher & Destany at 9:00 pm at RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Secure your pre-sale spot for $30 (Non-Refundable), or pay $40 at the door. RF Members attend for free. Don't just sit around at parties when the banda is playing! No partner is necessary, and all levels are welcome. Stick around after the class for an extra hour of practice and social dancing to Cumbia and Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/04/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/11/25">
                      <img src={Banda11} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 04/11</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher & Destany at 9:00 pm at RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Secure your pre-sale spot for $30 (Non-Refundable), or pay $40 at the door. RF Members attend for free. Don't just sit around at parties when the banda is playing! No partner is necessary, and all levels are welcome. Stick around after the class for an extra hour of practice and social dancing to Cumbia and Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/11/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/18/25">
                      <img src={Banda18} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Banda Class 04/18</h3>
                    <div className="news_content_area">
                      <p>
                        Join us for a fun Banda Class led by Christopher & Destany at 9:00 pm at RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Secure your pre-sale spot for $30 (Non-Refundable), or pay $40 at the door. RF Members attend for free. Don't just sit around at parties when the banda is playing! No partner is necessary, and all levels are welcome. Stick around after the class for an extra hour of practice and social dancing to Cumbia and Banda! Secure your spot and join the fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/18/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

              </div>

              {/* Spanish */}
              <h3>Spanish:</h3>
              <div className="spanish-event">

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://rfdance.com/teams/">
                      <img src={Banda14} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">RF Team Placements 2025</h3>
                    <div className="news_content_area">
                      <p>
                        ¿Listo para encontrar tu lugar en la comunidad de baile? ¡Las Colocaciones de Equipos RF 2025 son este abril! Únete a nosotros en RF Dance Center en Santa Ana para colocaciones gratuitas en Salsa, Bachata y Cumbia. ¡Todos los niveles son bienvenidos—no se necesita experiencia! Descubre tu pasión, supérate, y únete a la familia. Regístrate ahora en rfdance.com/teams, ¡el espacio es limitado! Usa ropa de ensayo cómoda y zapatos de baile. ¿Preguntas? Contáctanos en info@rfdance.com o 657.293.5161
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://rfdance.com/teams/">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/04/25">
                      <img src={Banda04} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda 04/04</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por Christopher y Destany a las 9:00 p.m. en RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Asegura tu lugar en preventa por $30 (no reembolsable), o paga $40 en la puerta. ¡Los miembros de RF asisten gratis! No te quedes sentado en las fiestas cuando suena la banda. ¡No necesitas pareja y todos los niveles son bienvenidos! Quédate después de la clase para una hora extra de práctica y baile social de Cumbia y Banda. ¡Asegura tu lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/04/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/11/25">
                      <img src={Banda11} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda 04/11</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por Christopher y Destany a las 9:00 p.m. en RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Asegura tu lugar en preventa por $30 (no reembolsable), o paga $40 en la puerta. ¡Los miembros de RF asisten gratis! No te quedes sentado en las fiestas cuando suena la banda. ¡No necesitas pareja y todos los niveles son bienvenidos! Quédate después de la clase para una hora extra de práctica y baile social de Cumbia y Banda. ¡Asegura tu lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/11/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="news_item">
                  <div className="image_container">
                    <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/18/25">
                      <img src={Banda18} alt="  BandaClass " />
                    </Link>
                  </div>
                  <div className="news_content">
                    <h3 className="news_heading">Clase de Banda 04/18</h3>
                    <div className="news_content_area">
                      <p>
                        Únete a una divertida clase de Banda dirigida por Christopher y Destany a las 9:00 p.m. en RF Dance Center (1517 N Main St, Santa Ana, CA 92701). Asegura tu lugar en preventa por $30 (no reembolsable), o paga $40 en la puerta. ¡Los miembros de RF asisten gratis! No te quedes sentado en las fiestas cuando suena la banda. ¡No necesitas pareja y todos los niveles son bienvenidos! Quédate después de la clase para una hora extra de práctica y baile social de Cumbia y Banda. ¡Asegura tu lugar y únete a la fiesta!
                      </p>
                    </div>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=55&sVT=81&sView=day&sLoc=1&sTrn=100000150&date=04/18/25">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;
